import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import 'moment/locale/fr';
import ExcelJS from 'exceljs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

let cache = {};

function formatOrdersForExport(data) {
  // Obtenez toutes les clés d'indicateurs uniques
  const allIndicatorKeys = new Set();
  data.forEach(item => {
    Object.keys(item.indicators).forEach(key => allIndicatorKeys.add(key));
  });

  // Initialisez une map pour vérifier la présence de données dans les colonnes supplémentaires
  const additionalColumnsPresence = {
    "Protections Cormorans": false,
    "Dates Aération": false,
    "Coût de Pompage": false,
    "Temps de Pêche": false,
    "Travaux d'Entretien": false,
    "Données Régulations": false,
    "Coût Fertilisation": false,
    "Prix Chaulage": false
  };

  const formattedData = data.map(item => {
    // Créez l'objet de base avec les colonnes statiques
    const formattedItem = {
      "Date Début Campagne": moment(item.campaign.startDate).format('YYYY-MM-DD'),
      "Date Fin Campagne": moment(item.campaign.endDate).format('YYYY-MM-DD'),
      "Nom Étang": item.waterBody.name,
      "Superficie Étang": item.waterBody.waterSurface,
      "Ville": item.waterBody.town,
      "Propriétaire Actuel": item.waterBody.currentOwner || 'N/A',
      "Profondeur Moyenne": item.waterBody.averageDepth || 'N/A',
      "Aérateur": item.waterBody.aerator ? 'Oui' : 'Non',
      "Électricité": item.waterBody.electricity ? 'Oui' : 'Non',
      "Nourrisseur": item.waterBody.feeder ? 'Oui' : 'Non',
      "Distance": item.waterBody.distance || 'N/A',
    };

    // Ajoutez les indicateurs après la colonne "Distance"
    allIndicatorKeys.forEach(key => {
      formattedItem[key] = item.indicators[key] !== undefined ? item.indicators[key] : 'N/A';
    });

    // Ajoutez les autres colonnes après les indicateurs, en marquant leur présence si elles ont des données
    const additionalColumns = {
      "Protections Cormorans": item.waterBody.protectionsCormorans?.map(p => `${p.type} (${p.number})`).join(', ') || 'N/A',
      "Dates Aération": item.waterBody.datesAeration?.map(d => `${moment(d.startDate).format('YYYY/MM/DD')} ${moment(d.startTime, 'HH:mm:ss').format('HH:mm')} - ${moment(d.endDate).format('YYYY/MM/DD')} ${moment(d.endTime, 'HH:mm:ss').format('HH:mm')}`).join(', ') || 'N/A',
      "Coût de Pompage": item.waterBody.coutPompage?.map(p => p.estimatedCost).join(', ') || 'N/A',
      "Temps de Pêche": item.waterBody.tempsPeche?.map(p => `Pêcheurs: ${p.fishermenNumber}, Coût: ${p.estimatedCost}`).join(', ') || 'N/A',
      "Travaux d'Entretien": item.waterBody.travauxEntretien?.map(t => `Coût estimé: ${t.estimatedCost}`).join(', ') || 'N/A',
      "Données Régulations": item.waterBody.donneesRegulations?.map(r => `Animal: ${r.name}, Vus: ${r[0].trappedNumber}, Tirés: ${r[0].firedNumber}, Piégés: ${r[0].killedNumber}`).join(', ') || 'N/A',
      "Coût Fertilisation": item.waterBody.coutFertilization?.map(cf => cf.estimatedCost).join(', ') || 'N/A',
      "Prix Chaulage": item.waterBody.prixChaulage?.map(pc => pc.purchasePrice * pc.quantity).join(', ') || 'N/A'
    };

    // Vérifiez si les colonnes additionnelles ont des données
    Object.keys(additionalColumns).forEach(key => {
      if (additionalColumns[key] !== 'N/A') {
        additionalColumnsPresence[key] = true;
      }
    });

    // Ajoutez les colonnes additionnelles à l'objet formaté
    return { ...formattedItem, ...additionalColumns };
  });

  // Filtrer les colonnes additionnelles sans données
  const columnsToRemove = Object.keys(additionalColumnsPresence).filter(key => !additionalColumnsPresence[key]);
  return formattedData.map(item => {
    columnsToRemove.forEach(column => {
      delete item[column];
    });
    return item;
  });
}

export async function orderExporter(data, fetchRelatedRecords, dataProvider, exportFileName) {
  const ordersForExport = formatOrdersForExport(data);
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(exportFileName);
  const headerRow = worksheet.addRow(Object.keys(ordersForExport[0]));
  headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } };
  headerRow.eachCell(cell => {
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    if (cell.value) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4e73df' }
      };
    }
  });

  ordersForExport.forEach(order => {
    const row = worksheet.addRow(Object.values(order));
    row.eachCell(cell => {
      cell.alignment = { horizontal: 'left' };
    });
  });
  worksheet.getRow(1).height = 30;

  const defaultColumnWidth = 20;
  const specificColumnWidths = {
    2: 28,
    6: 25,
    10: 25
  };
  worksheet.columns.forEach((column, columnIndex) => {
    if (specificColumnWidths[columnIndex + 1]) {
      column.width = specificColumnWidths[columnIndex + 1];
    } else {
      column.width = defaultColumnWidth;
    }
  });
  workbook.xlsx.writeBuffer().then(buffer => {
    downloadExcel(buffer, exportFileName);
  });
}

export async function orderExporterPDF(data, fetchRelatedRecords, dataProvider, campaignValue, exportFileName) {
  const ordersForExport = formatOrdersForExport(data);

  const pdfContent = {
    pageOrientation: 'landscape',
    content: [
      { text: exportFileName, style: 'title' }
    ],
    styles: {
      title: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      header: {
        color: 'white',
        fontSize: 6,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      body: {
        fontSize: 6,
      },
    }
  };
  const defaultColumnWidth = 85;
  const specificColumnWidths = {};

  const tableHeader = Object.keys(ordersForExport[0]);
  const tableBody = ordersForExport.map(order => Object.values(order));
  const styledTableBody = tableBody.map(row => {
    return row.map(cellValue => ({ text: cellValue, style: 'body' }));
  });
  pdfContent.content.push({
    layout: {
      fillColor: function (rowIndex, node, columnIndex) {
        return rowIndex === 0 ? '#4e73df' : null; // If it's the header row, set the background color.
      },
      hLineColor: function (i, node) {
        return (i === 0 || i === node.table.body.length) ? 'black' : '#AAAAAA';
      },
      vLineColor: function (i, node) {
        return '#AAAAAA';
      },
      paddingLeft: function (i, node) { return 3.5; },
      paddingRight: function (i, node) { return 3.5; },
      paddingTop: function (i, node) { return 3.5; },
      paddingBottom: function (i, node) { return 3.5; }
    },
    table: {
      headerRows: 1,
      widths: tableHeader.map((_, index) => specificColumnWidths[index + 1] || defaultColumnWidth),
      body: [[...tableHeader.map(header => ({ text: header, style: 'header' }))], ...styledTableBody]
    }
  });

  pdfMake.createPdf(pdfContent).download(`${exportFileName}.pdf`);
}

const downloadExcel = (buffer, filename) => {
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function clearCache() {
  cache = {};
}
clearCache();

export default orderExporter;
