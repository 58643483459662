import React, { useState, useEffect } from 'react';
import { useDataProvider, ExportButton } from 'react-admin';
import { Table, TableHead, TableRow, TableCell, TableBody, Paper, TableContainer, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';
import { orderExporter, orderExporterPDF } from '../Tools/ExportExcel/Multirequester/exportMR';

const ViewResults = () => {
  const [data, setData] = useState([]);
  const [indicatorKeys, setIndicatorKeys] = useState([]);
  const dataProvider = useDataProvider();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const retrievedData = location.state?.data || [];

    // Calculate the correct 'marge brute / ha' for each item
    const calculatedData = retrievedData.map(item => {
      const surfaceArea = item.waterBody.waterSurface; // Surface area in hectares
      if (item.indicators['marge_brute']) {
        // Compute the gross margin per hectare
        item.indicators['marge_brute_ha'] = item.indicators['marge_brute'] / surfaceArea;
      }
      return item;
    });

    setData(calculatedData);

    // Collect unique indicator keys
    const keys = new Set();
    calculatedData.forEach(item => {
      Object.keys(item.indicators).forEach(key => keys.add(key));
    });
    setIndicatorKeys(Array.from(keys));
  }, [location.state]);

  let exportFileName = 'MR';

    function addToIfNotExist(array, item) {
        if (!array.includes(item)) {
            array.push(item);
            return true;  // Item was added because it wasn't found
        }
        return false;  // Item was not added because it already exists
    }

    function addToIfNotExistSuplementaire (array, item) {
        if (!array.includes(item)) {
            array.push(item);
            return true;  // Item was added because it wasn't found
        }
        return false;  // Item was not added because it already exists

    }

    const formatKey = (key) => {
    const customLabels = {
        'coeff_multiplicateur': 'Coefficient Multiplicateur',
        'marge_brute_ha': 'Marge Brut par Ha',
        'charge_brute_ha': 'Charges par Ha',
        'empoissonnage_kg_ha': 'Empoissonnage en Kg',
        'marges_brutes_kg_ha': 'Marge Brute par Kg',
        'rendements_net_kg_ha': 'Rendement Net en Kg/Ha',
        'coefficient_d_accroissement_globaux': 'Coefficient d’Accroissement Globaux',
        'rendement_brut_global': 'Rendement Brut Global',
        'coeff_accroissement_moyen': 'Coefficient d’Accroissement Moyen',
        'prix_moyen_vente': 'Prix Moyen de Vente',
        'empoissonnement_kg_ha': 'Empoissonnement en Kg/Ha',
        'protections_cormorans': 'Protections Cormorans',
        'dates_aeration': 'Dates d’Aération',
        'cout_pompage': 'Coût de Pompage',
        'temps_peche': 'Temps de Pêche',
        'travaux_entretien': 'Travaux d’Entretien',
        'donnees_regulations': 'Données de Régulation',
        'cout_fertilization': 'Coût de Fertilisation',
        'prix_chaulage': 'Prix du Chaulage',
        'empoissonnement_kg_ha_spieces': 'Empoissonnage en kg / ha par catégorie d\'espèce de poissons',
        'peche_kg_ha_spieces': 'Pêche en kg/ha par catégorie d\'espèce de poisson',
        // Add more custom labels as needed
    };

    if (customLabels[key]) {
        return customLabels[key];
    }

    return key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};


    const hasData = (key) => {
        const keys = key.split('.'); // Divise la clé en sous-clés basées sur les points.
        return data.some(item => {
            let current = item;
            for (const keyPart of keys) {
                if (current[keyPart] === undefined) {
                    return false; // Si une sous-clé n'existe pas, retourne false immédiatement.
                }
                current = current[keyPart]; // Accède à la sous-clé suivante.
            }
            return current && current.length > 0; // Vérifie si la valeur finale est non nulle et non vide.
        });
    };


    const hasIndicatorData = () => {
    return indicatorKeys.length > 0;
  };
    let spieciedouble = [];
    let spieciedouble1 = [];
    let spieciedouble2 = [];
    let namedouble = [];
    let nametravauxEntretiendouble = [];
    let cormoranval = [];
    let nameReguldouble = [];

    return (

    <div style={{ padding: '20px' }}>
      <h2>Résultats</h2>
      {data.length > 0 ? (
      <div style={{ marginBottom: 20, marginTop: 10, display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
        <Button variant="contained" color="primary" onClick={() => orderExporter(data, null, dataProvider, exportFileName)}>Exporter (Excel)</Button>
      </div>
      ) : (
        ''
      )}
      {data.length > 0 ? (
        <Paper>
          <TableContainer style={{ maxHeight: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Début Campagne</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Fin Campagne</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Nom Prénom</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Nom Étang</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Superficie Étang</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Ville</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Propriétaire Actuel</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Profondeur Moyenne</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Aérateur</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Électricité</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Nourrisseur</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Distance</TableCell>
                    {
                        hasIndicatorData() && indicatorKeys.map(key => {
                            if (formatKey(key) == "Coeff Accroissement Globaux") {
                                // return data.map((item, index) => {
                                //     if (item.indicators && item.indicators.coeff_accroissement_globaux) {
                                //         // Mappe sur chaque clé dans coeff_accroissement_globaux et les rend dans des TableCell séparées
                                //         return Object.keys(item.indicators.coeff_accroissement_globaux).map((subKey, subIndex) => {
                                //             return (
                                //                 <TableCell key={`${key}-${index}-${subIndex}`} style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>
                                //                     {"Coeff Accroissement Globaux : " + subKey}
                                //                 </TableCell>
                                //             );
                                //         });
                                //     }
                                //     return null;
                                // });

                                return data.map((item, index) => {
                                    // Check if the specific indicator is present and is an object
                                    if (item.indicators && typeof item.indicators.coeff_accroissement_globaux === 'object') {

                                        // Use Object.entries to convert the object into an array of [key, value] pairs
                                        const entries = Object.entries(item.indicators.coeff_accroissement_globaux);

                                        // Map over the entries to create a TableCell for each key-value pair
                                        const cells = entries.map(([species, weight], subIndex) => {
                                            // Only process this species if it has not been processed before
                                            if (addToIfNotExist(spieciedouble2, species)) {
                                                return (
                                                    <TableCell key={`${index}-${subIndex}`} style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>
                                                        {`Coeff Accroissement Globaux : ${species}`}
                                                    </TableCell>
                                                );
                                            }
                                            // Optionally handle or ignore duplicate species
                                            return null; // Uncomment this if you want to omit cells for duplicate species
                                        });

                                        return cells; // Return an array of TableCell components
                                    }
                                    // Return a TableCell with "N/A" if the indicators do not exist or are not properly formatted
                                    return <TableCell key={`${index}-na`} style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>N/A</TableCell>;
                                });


                            } else if (formatKey(key) === "Empoissonnage en kg / ha par catégorie d'espèce de poissons") {


                                return data.map((item, index) => {
                                    // Check if the specific indicator is present and is an object
                                    if (item.indicators && typeof item.indicators.empoissonnement_kg_ha_spieces === 'object') {

                                        // Use Object.entries to convert the object into an array of [key, value] pairs
                                        const entries = Object.entries(item.indicators.empoissonnement_kg_ha_spieces);

                                        // Map over the entries to create a TableCell for each key-value pair
                                        const cells = entries.map(([species, weight], subIndex) => {
                                            // Only process this species if it has not been processed before
                                            if (addToIfNotExist(spieciedouble, species)) {
                                                return (
                                                    <TableCell key={`${index}-${subIndex}`} style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>
                                                        {`Empoissonnage en kg / ha par ${species}`}
                                                    </TableCell>
                                                );
                                            }
                                            // Optionally handle or ignore duplicate species
                                             return null; // Uncomment this if you want to omit cells for duplicate species
                                        });

                                        return cells; // Return an array of TableCell components
                                    }
                                    // Return a TableCell with "N/A" if the indicators do not exist or are not properly formatted
                                    return <TableCell key={`${index}-na`} style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>N/A</TableCell>;
                                });
                            }
                            else if (formatKey(key) === "Pêche en kg/ha par catégorie d'espèce de poisson") {


                                return data.map((item, index) => {
                                    // Check if the specific indicator is present and is an object
                                    if (item.indicators && typeof item.indicators.peche_kg_ha_spieces === 'object') {

                                        // Use Object.entries to convert the object into an array of [key, value] pairs
                                        const entries = Object.entries(item.indicators.peche_kg_ha_spieces);

                                        // Map over the entries to create a TableCell for each key-value pair
                                        const cells = entries.map(([species, weight], subIndex) => {
                                            // Only process this species if it has not been processed before
                                            if (addToIfNotExist(spieciedouble1, species)) {
                                                return (
                                                    <TableCell key={`${index}-${subIndex}`} style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>
                                                        {`Peche en kg / ha par ${species}`}
                                                    </TableCell>
                                                );
                                            }
                                            // Optionally handle or ignore duplicate species
                                            return null; // Uncomment this if you want to omit cells for duplicate species
                                        });

                                        return cells; // Return an array of TableCell components
                                    }
                                    // Return a TableCell with "N/A" if the indicators do not exist or are not properly formatted
                                    return <TableCell key={`${index}-na`} style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>N/A</TableCell>;
                                });
                            }

                            else {
                                return (
                                    <TableCell key={key} style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>
                                        {formatKey(key)}
                                    </TableCell>
                                );


                                // Mappe sur chaque item dans data lorsque la clé est "Coeff Accroissement Globaux"

                            }
                        })
                    }



                    {
                        data.map((item, index) => {
                            if (item.waterBody && item.waterBody.protectionsCormorans && typeof item.waterBody.protectionsCormorans === 'object') {
                                // Convertir l'objet en tableau si ce n'est pas déjà un tableau
                                const protectionsArray = Array.isArray(item.waterBody.protectionsCormorans)
                                    ? item.waterBody.protectionsCormorans
                                    : Object.values(item.waterBody.protectionsCormorans);

                                return protectionsArray.map((item1, index1) => {

                                    // Générer une TableCell pour chaque élément dans protectionsCormorans

                                    if (addToIfNotExistSuplementaire(namedouble, item1['name'])) {
                                        // cormoranval[index][item1] = item1[0]["number"];
                                        return (
                                            <TableCell key={`${index}-${index1}`} style={{
                                                minWidth: 150,
                                                color: 'white',
                                                backgroundColor: '#4e73df'
                                            }}>
                                                Protections Cormorans ({item1.name})
                                            </TableCell>
                                        );
                                    } else {
                                        // cormoranval[index][item1['name']] = item1[0]["number"];
                                    }
                                });
                            }
                        })
                    }




                  {hasData('waterBody.datesAeration') && <TableCell style={{ minWidth: 200, color: 'white', backgroundColor: '#4e73df' }}>Dates Aération</TableCell>}
                  {hasData('waterBody.coutPompage') && <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Coût de Pompage</TableCell>}




                  {hasData('waterBody.tempsPeche') && <TableCell style={{ minWidth: 200, color: 'white', backgroundColor: '#4e73df' }}>Temps de Pêche (Pêcheurs)</TableCell>}
                  {hasData('waterBody.tempsPeche') && <TableCell style={{ minWidth: 200, color: 'white', backgroundColor: '#4e73df' }}>Temps de Pêche (Coût)</TableCell>}
                  {hasData('waterBody.tempsPeche') && <TableCell style={{ minWidth: 200, color: 'white', backgroundColor: '#4e73df' }}>Temps de Pêche (Temps de pêche)</TableCell>}




                  {hasData('waterBody.travauxEntretien') && <TableCell style={{ minWidth: 200, color: 'white', backgroundColor: '#4e73df' }}>Travaux d'Entretien</TableCell>}




                    {
                        data.map((item, index) => {
                            if (item.waterBody && item.waterBody.travauxEntretien && typeof item.waterBody.travauxEntretien === 'object') {
                                // Convertir l'objet en tableau si ce n'est pas déjà un tableau
                                const protectionsArray = Array.isArray(item.waterBody.travauxEntretien)
                                    ? item.waterBody.travauxEntretien
                                    : Object.values(item.waterBody.travauxEntretien);

                                return protectionsArray.map((item1, index1) => {

                                    // Générer une TableCell pour chaque élément dans protectionsCormorans
                                    console.log(item1.workDone);

                                    if (addToIfNotExistSuplementaire(nametravauxEntretiendouble, item1.workDone)) {
                                        // cormoranval[index][item1] = item1[0]["number"];
                                        return (
                                            <TableCell key={`${index}-${index1}`} style={{
                                                minWidth: 150,
                                                color: 'white',
                                                backgroundColor: '#4e73df'
                                            }}>
                                                Travaux d'Entretien({item1.workDone})
                                            </TableCell>
                                        );
                                    } else {
                                        // cormoranval[index][item1['name']] = item1[0]["number"];
                                    }
                                });
                            }
                        })
                    }

                    {
                        data.map((item, index) => {
                            if (item.waterBody && item.waterBody.donneesRegulations && typeof item.waterBody.donneesRegulations === 'object') {
                                // Convertir l'objet en tableau de paires clé-valeur
                                const regulationsArray = Array.isArray(item.waterBody.donneesRegulations)
                                    ? item.waterBody.donneesRegulations.map((el, idx) => [idx.toString(), el])
                                    : Object.entries(item.waterBody.donneesRegulations);

                                return regulationsArray.map(([key, value], index1) => {
                                    console.log(key); // Cela affichera la clé réelle au lieu de l'index numérique
                                    if (addToIfNotExistSuplementaire(nameReguldouble, key)) {
                                        return (
                                            <TableCell key={`${index}-${key}`} style={{
                                                minWidth: 150,
                                                color: 'white',
                                                backgroundColor: '#4e73df'
                                            }}>
                                                Données Régulations ({key})
                                            </TableCell>
                                        );
                                    }
                                });
                            }
                        })
                    }



















                  {hasData('waterBody.coutFertilization') && <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Coût Fertilisation</TableCell>}
                  {hasData('waterBody.prixChaulage') && <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Prix Chaulage</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {


                    data.map((item, index) => (
                        <TableRow key={index}>
                    <TableCell>{moment(item.campaign.startDate).format('YYYY/MM/DD')}</TableCell>
                    <TableCell>{moment(item.campaign.endDate).format('YYYY/MM/DD')}</TableCell>
                    <TableCell>{item.waterBody.user.firstName || 'N/A'} {item.waterBody.user.lastName || 'N/A'}</TableCell>
                    <TableCell>{item.waterBody.name}</TableCell>
                    <TableCell>{item.waterBody.waterSurface}</TableCell>
                    <TableCell>{item.waterBody.town}</TableCell>
                    <TableCell>{item.waterBody.currentOwner || 'N/A'}</TableCell>
                    <TableCell>{item.waterBody.averageDepth || 'N/A'}</TableCell>
                    <TableCell>{item.waterBody.aerator ? 'Oui' : 'Non'}</TableCell>
                    <TableCell>{item.waterBody.electricity ? 'Oui' : 'Non'}</TableCell>
                    <TableCell>{item.waterBody.feeder ? 'Oui' : 'Non'}</TableCell>
                    <TableCell>{item.waterBody.distance || 'N/A'}</TableCell>
                            {indicatorKeys.map(key => {
                                if (key === "coeff_accroissement_globaux") {
                                    // return (
                                    //     item.indicators[key] && Object.keys(item.indicators[key]).length > 0
                                    //         ? Object.entries(item.indicators[key]).map(([subKey, value], subIndex) => (
                                    //             <TableCell key={`${key}-${subKey}-${subIndex}`}>{value ? `${subKey}: ${value}` : 'N/A'}</TableCell>
                                    //         ))
                                    //         : <TableCell key={`${key}-na`}>N/A</TableCell>
                                    // );

                                    // Log existing data if available


                                    // Convert object to array of entries
                                    const entries = Object.entries(item.indicators[key]);

                                    return (
                                        // Ensure spieciedouble is not null before mapping
                                        spieciedouble2 !== null
                                            ? spieciedouble2.map(([subKey, value], subIndex) => {
                                                // Find a matching entry using the find() method
                                                const foundEntry = entries.find(([entryKey, entryValue]) => entryKey === spieciedouble2[subIndex][0] && entryValue === spieciedouble2[subIndex][1]);
                                                return (
                                                    <TableCell key={`${key}-na`}>{`${item.indicators[key][spieciedouble2[subIndex]]}`}</TableCell>
                                                );
                                            })
                                            : <TableCell key={`${key}-na`}>N/A</TableCell>
                                    );



                                } else if (key === "empoissonnement_kg_ha_spieces") {
                                    // Log existing data if available


                                    // Convert object to array of entries
                                    const entries = Object.entries(item.indicators[key]);

                                    return (
                                        // Ensure spieciedouble is not null before mapping
                                        spieciedouble !== null
                                            ? spieciedouble.map(([subKey, value], subIndex) => {
                                                // Find a matching entry using the find() method
                                                const foundEntry = entries.find(([entryKey, entryValue]) => entryKey === spieciedouble[subIndex][0] && entryValue === spieciedouble[subIndex][1]);
                                                return (
                                                    <TableCell key={`${key}-na`}>{`${item.indicators[key][spieciedouble[subIndex]]}`}</TableCell>
                                                );
                                            })
                                            : <TableCell key={`${key}-na`}>N/A</TableCell>
                                    );
                                }else if (key === "peche_kg_ha_spieces") {
                                    // Log existing data if available


                                    // Convert object to array of entries
                                    const entries = Object.entries(item.indicators[key]);

                                    return (
                                        // Ensure spieciedouble is not null before mapping
                                        spieciedouble1 !== null
                                            ? spieciedouble1.map(([subKey, value], subIndex) => {
                                                // Find a matching entry using the find() method
                                                const foundEntry = entries.find(([entryKey, entryValue]) => entryKey === spieciedouble1[subIndex][0] && entryValue === spieciedouble[subIndex][1]);
                                                return (
                                                    <TableCell key={`${key}-na`}>{`${item.indicators[key][spieciedouble1[subIndex]]}`}</TableCell>
                                                );
                                            })
                                            : <TableCell key={`${key}-na`}>N/A</TableCell>
                                    );
                                }else {
                                    return (
                                        <TableCell key={key}>
                                            {item.indicators[key] && typeof item.indicators[key] === 'object'
                                                ? (Array.isArray(item.indicators[key]) && item.indicators[key].length === 0)
                                                    ? 'N/A'
                                                    : Object.entries(item.indicators[key]).length === 0
                                                        ? 'N/A'
                                                        : Object.entries(item.indicators[key]).map(([subKey, value]) => (
                                                            <div key={subKey}>{`${subKey}: ${value}`}</div>
                                                        ))
                                                : (item.indicators[key] || 'N/A')}
                                        </TableCell>
                                    );
                                }
                            })}







                            {
                                namedouble.map((tetedecolonne, index) => {
                                    // Afficher dans la console la clé utilisée pour chaque itération
                                    // Vérifier et afficher la valeur associée à chaque clé dans l'objet
                                    if (item.waterBody.protectionsCormorans.hasOwnProperty(tetedecolonne)) {
                                        return (
                                            <TableCell key={index}>
                                                <div>{item.waterBody.protectionsCormorans[tetedecolonne][0].number}</div>
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={index}>N/A</TableCell>
                                        );
                                    }
                                })
                            }







                            <TableCell>
                        {item.waterBody.datesAeration?.map(d => (
                          <div key={d.id}>
                            {`${moment(d.startDate).format('YYYY/MM/DD')} ${moment(d.startTime, 'HH:mm:ss').format('HH:mm')} - ${moment(d.endDate).format('YYYY/MM/DD')} ${moment(d.endTime, 'HH:mm:ss').format('HH:mm')}`}
                          </div>
                        )) || 'N/A'}
                      </TableCell>

                    {hasData('waterBody.coutPompage') && (
                      <TableCell>
                        {item.waterBody.coutPompage?.map(p => (
                          <div key={p.id}>{p.estimatedCost}</div>
                        )) || 'N/A'}
                      </TableCell>
                    )}





                            {
                                hasData('waterBody.tempsPeche') && (
                                    <>
                                        <TableCell>
                                            {item.waterBody.tempsPeche?.length > 0 ? (
                                                item.waterBody.tempsPeche.map(p => (
                                                    <div key={p.id}>{p.fishermenNumber}</div>
                                                ))
                                            ) : 'N/A'}
                                        </TableCell>
                                        <TableCell>
                                            {item.waterBody.tempsPeche?.length > 0 ? (
                                                item.waterBody.tempsPeche.map(p => (
                                                    <div key={p.id}>{p.estimatedCost}</div>
                                                ))
                                            ) : 'N/A'}
                                        </TableCell>
                                        <TableCell>
                                            {item.waterBody.tempsPeche?.length > 0 ? (
                                                item.waterBody.tempsPeche.map(p => (
                                                    <div key={p.id}>{p.fishingTime}</div>
                                                ))
                                            ) : 'N/A'}
                                        </TableCell>
                                    </>
                                )
                            }




                    {/*{hasData('waterBody.travauxEntretien') && (*/}
                    {/*  <TableCell>*/}
                    {/*    {nametravauxEntretiendouble?.map(t => (*/}
                    {/*      <div key={t.id}>{`Travail réalisé: ${t.workDone}`}<br />*/}
                    {/*      {`Coût estimé: ${t.estimatedCost}`}</div>*/}
                    {/*    )) || 'N/A'}*/}
                    {/*  </TableCell>*/}
                    {/*)}*/}


                            {
                                nametravauxEntretiendouble.map((tetedecolonne, index) => {
                                    // Afficher dans la console la clé utilisée pour chaque itération
                                    // Vérifier et afficher la valeur associée à chaque clé dans l'objet
                                    if (item.waterBody.travauxEntretien.hasOwnProperty(tetedecolonne)) {
                                        return (
                                            <TableCell key={index}>
                                                <div>{item.waterBody.travauxEntretien[tetedecolonne].estimatedCost}</div>
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={index}>N/A</TableCell>
                                        );
                                    }
                                })
                            }



                            {
                                nameReguldouble.map((tetedecolonne, index) => {
                                    // Afficher dans la console la clé utilisée pour chaque itération
                                    // Vérifier et afficher la valeur associée à chaque clé dans l'objet
                                    if (item.waterBody.donneesRegulations.hasOwnProperty(tetedecolonne)) {
                                        console.log(item.waterBody.donneesRegulations);
                                        return (
                                            <TableCell key={index}>
                                                <div>{item.waterBody.donneesRegulations[tetedecolonne]}</div>
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={index}>N/A</TableCell>
                                        );
                                    }
                                })
                            }





                    {/*{hasData('waterBody.donneesRegulations') && (*/}
                    {/*  <TableCell>*/}
                    {/*    {item.waterBody.donneesRegulations?.map(r => (*/}
                    {/*      <div key={r.id}>*/}
                    {/*        {`Animal: ${r.name}`}<br />*/}
                    {/*        {`Vus: ${r[0].trappedNumber}`}<br />*/}
                    {/*        {`Tirés: ${r[0].firedNumber}`}<br />*/}
                    {/*        {`Piégés: ${r[0].killedNumber}`}*/}
                    {/*      </div>*/}
                    {/*    )) || 'N/A'}*/}
                    {/*  </TableCell>*/}
                    {/*)}*/}

                    {hasData('waterBody.coutFertilization') && (
                      <TableCell>
                        {item.waterBody.coutFertilization?.map(cf => (
                          <div key={cf.id}>{cf.price * cf.quantity}</div>
                        )) || 'N/A'}
                      </TableCell>
                    )}
                    {hasData('waterBody.prixChaulage') && (
                      <TableCell>
                        {item.waterBody.prixChaulage?.map(pc => (
                          <div key={pc.id}>{pc.purchasePrice * pc.quantity}</div>
                        )) || 'N/A'}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <p>Aucune donnée disponible</p>
      )}
    </div>
  );
};

export default ViewResults;
